import React from 'react'
import './styles.scss'

export default function BoothPreview(props) {
  console.log({ props })
  const { backgroundImage, title, buttonPosition } = props

  return (
    <>
      <div id="previewBoothContainer">
        <img
          id="booth-image"
          src={backgroundImage.url}
          alt="booth backdrop"
          width="100%"
        />
        <button className="hot-buttons" style={buttonPosition.tv}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polygon points="10 8 16 12 10 16 10 8"></polygon>
          </svg>
          <small>Video</small>
        </button>
        <button className="hot-buttons" style={buttonPosition.wp}>
          <svg width="20" height="20" viewBox="0 0 45.448 45.448">
            <path d="M0,0H45.448V45.448H0Z" fill="none" />
            <path
              d="M37.086,33.3V6.787A3.8,3.8,0,0,0,33.3,3H6.787A3.8,3.8,0,0,0,3,6.787V33.3a3.8,3.8,0,0,0,3.787,3.787H33.3A3.8,3.8,0,0,0,37.086,33.3ZM14.173,23.793l3.977,4.791,5.87-7.556a.951.951,0,0,1,1.515.019l6.647,8.862a.946.946,0,0,1-.757,1.515H8.719A.943.943,0,0,1,7.98,29.89L12.7,23.83A.934.934,0,0,1,14.173,23.793Z"
              transform="translate(2.681 2.681)"
              fill="#fff"
            />
          </svg>
          <small>Gallery</small>
        </button>
        <button className="hot-buttons" style={buttonPosition.sp}>
          <svg width="20" height="20" viewBox="0 0 45.448 45.448">
            <path d="M0,0H45.448V45.448H0Z" fill="none" />
            <path
              d="M37.086,33.3V6.787A3.8,3.8,0,0,0,33.3,3H6.787A3.8,3.8,0,0,0,3,6.787V33.3a3.8,3.8,0,0,0,3.787,3.787H33.3A3.8,3.8,0,0,0,37.086,33.3ZM14.173,23.793l3.977,4.791,5.87-7.556a.951.951,0,0,1,1.515.019l6.647,8.862a.946.946,0,0,1-.757,1.515H8.719A.943.943,0,0,1,7.98,29.89L12.7,23.83A.934.934,0,0,1,14.173,23.793Z"
              transform="translate(2.681 2.681)"
              fill="#fff"
            />
          </svg>
          <small>Banner</small>
        </button>
        <button className="hot-buttons" style={buttonPosition.namecard}>
          <svg width="28.393" height="23.935" viewBox="0 0 28.393 23.935">
            <path
              d="M28.256,18.7a14.586,14.586,0,0,1-2.475-3.636c-.143-.382-.321-.921-.526-1.538-.98-2.954-1.871-5.466-2.859-6.3-1.071-.9-5.208-1.5-6.909-1.719V2.176A2.407,2.407,0,0,0,12.906,0H2.581A2.407,2.407,0,0,0,0,2.176V17.407a2.407,2.407,0,0,0,2.581,2.176H12.906a2.9,2.9,0,0,0,.811-.129,11.365,11.365,0,0,0,1.866,1.115,9.152,9.152,0,0,1,3.863,3.095.675.675,0,0,0,.558.271,9.1,9.1,0,0,0,8.346-4.7A.476.476,0,0,0,28.256,18.7ZM15.487,10.051l2.045.69c.1.3.27.773.44,1.146a4.082,4.082,0,0,0-.217.385,2.946,2.946,0,0,1-.424.662,6.49,6.49,0,0,1-1.843-2.085ZM2.581,18.5a1.2,1.2,0,0,1-1.291-1.088V2.176A1.2,1.2,0,0,1,2.581,1.088H12.906A1.2,1.2,0,0,1,14.2,2.176V8.7A6.631,6.631,0,0,0,10.8,6.313a1.868,1.868,0,0,0-1.579.374c-1.554,1.31-.664,3.536.57,5.045a8.133,8.133,0,0,1,1.825,3.551c.017.152.025.3.033.439a4.177,4.177,0,0,0,1.12,2.773Zm17.783,4.345a10.632,10.632,0,0,0-4.112-3.2,6.885,6.885,0,0,1-2.179-1.464,3.448,3.448,0,0,1-1.132-2.5c-.009-.16-.018-.322-.038-.492a9.009,9.009,0,0,0-2.047-4.065c-1-1.216-1.644-2.879-.72-3.659a.445.445,0,0,1,.409-.078A6.09,6.09,0,0,1,13.6,10c.494,1.031,1.831,3.5,3.577,4.107a.756.756,0,0,0,.606-.05,2.949,2.949,0,0,0,1.155-1.343,3.224,3.224,0,0,0,.145-.294.5.5,0,0,0,.21-.691,10.8,10.8,0,0,1-.6-1.53.588.588,0,0,0-.384-.364l-2.821-.951V6.614c2.345.306,5.389.874,6,1.386.829.7,1.893,3.906,2.529,5.822.209.631.392,1.182.539,1.572A14.311,14.311,0,0,0,27,19.109,7.815,7.815,0,0,1,20.365,22.841Z"
              fill="#fff"
            />
            <path
              d="M85.978,42.667a.645.645,0,0,0-.645.645V54.928a.645.645,0,0,0,1.291,0V43.312A.645.645,0,0,0,85.978,42.667Z"
              transform="translate(-80.171 -40.086)"
              fill="#fff"
            />
            <path
              d="M43.311,42.667a.645.645,0,0,0-.645.645v6.453a.645.645,0,1,0,1.291,0V43.312A.645.645,0,0,0,43.311,42.667Z"
              transform="translate(-40.085 -40.086)"
              fill="#fff"
            />
          </svg>
          <small>Namecard</small>
        </button>
        <button className="hot-buttons" style={buttonPosition.pdf}>
          <svg width="20" height="20" viewBox="0 0 39.195 39.195">
            <path d="M0,0H39.195V39.195H0Z" fill="none" />
            <path
              d="M31.4,2H11.8A3.276,3.276,0,0,0,8.532,5.266v19.6A3.276,3.276,0,0,0,11.8,28.13H31.4a3.276,3.276,0,0,0,3.266-3.266V5.266A3.276,3.276,0,0,0,31.4,2ZM17.515,14.248a2.446,2.446,0,0,1-2.45,2.45H13.432v2.041a1.225,1.225,0,0,1-2.45,0V11.8a1.638,1.638,0,0,1,1.633-1.633h2.45a2.446,2.446,0,0,1,2.45,2.45Zm8.166,3.266a2.446,2.446,0,0,1-2.45,2.45H19.964a.809.809,0,0,1-.817-.817V10.982a.809.809,0,0,1,.817-.817H23.23a2.446,2.446,0,0,1,2.45,2.45Zm6.532-6.124a1.234,1.234,0,0,1-1.225,1.225H29.763v1.633h1.225a1.225,1.225,0,1,1,0,2.45H29.763v2.041a1.225,1.225,0,0,1-2.45,0V11.8a1.638,1.638,0,0,1,1.633-1.633h2.041A1.234,1.234,0,0,1,32.213,11.39ZM13.432,14.248h1.633V12.615H13.432Zm-9.8-5.716A1.638,1.638,0,0,0,2,10.166V31.4a3.276,3.276,0,0,0,3.266,3.266H26.5a1.633,1.633,0,0,0,0-3.266H6.9a1.638,1.638,0,0,1-1.633-1.633v-19.6A1.638,1.638,0,0,0,3.633,8.532ZM21.6,17.515H23.23v-4.9H21.6Z"
              transform="translate(1.266 1.266)"
              fill="#fff"
            />
          </svg>
          <small>PDF</small>
        </button>
        <button className="hot-buttons" style={buttonPosition.chat}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path fill="#ffffff" d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"></path>
          </svg>
          <small>Talk<br />To Us</small>
        </button>
        <button className="hot-buttons" style={buttonPosition.info}>
          <svg width="20" height="20" viewBox="0 0 40.754 41.254">
            <g transform="translate(-11.422 -5.916)">
              <g
                transform="translate(11.422 5.916)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
              >
                <circle cx="20.377" cy="20.377" r="20.377" stroke="none" />
                <circle cx="20.377" cy="20.377" r="19.377" fill="none" />
              </g>
              <text
                transform="translate(31.242 39.67)"
                fill="#fff"
                stroke="#fff"
                strokeWidth="0.5"
                fontSize="30"
                fontFamily="Helvetica"
              >
                <tspan x="-3.333" y="0">
                  i
                </tspan>
              </text>
            </g>
          </svg>
          <small className="extra-small">
            Exhibitor
            <br />
            Info
          </small>
        </button>
      </div>
      <h4 className="exhibitor-title">{title}</h4>
    </>
  )
}
