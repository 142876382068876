import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  token,
  url,
  physical,
  sst,
  masterclass,
  eligibleProfession,
  eligibleMemberType,
  purchasedPhysical,
  purchasedWorkshop,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null); // [null, product_id]

  const today = new Date();
  const march = new Date(today.getFullYear(), 2, 1); // Months are 0-indexed in JavaScript

  let sstRate = today < march ? 6 : 8;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedWorkshop]}
        />
        <h3 className="text-navy text-center pt-4 pb-1">
          Select Conference Ticket
        </h3>
        <ProductCard
          cardTitle={"MCOR 2024 Conference Ticket"}
          cardTitlePhysical1={
            "* MCOR 2024 Conference Ticket includes access to Masterclass in Ultrasound. Registration is required via your user dashboard to secure a seat. Limited to 150 seats only."
          }
          products={physical}
          purchasedPhysical={purchasedPhysical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              setSelectedWorkshop(null);
            } else {
              setSelectedWorkshop(null);
            }
          }}
          handleRadio={() => {
            setSelectedWorkshop(null);
          }}
        />

        {eligibleMemberType && (
          <>
            <h6 className="text-center text-muted mt-4">OR</h6>

            <h3 className="text-navy text-center pt-4 pb-1">
              Select Masterclass Ticket
            </h3>
            <ProductCard
              cardTitle={
                "MCOR2024 Masterclass in Ultrasound (limited to 150 seats only)"
              }
              cardTitleWorkshop1={
                "1-Day Ticket | 26th July 2024 | 9:30am – 5:15pm"
              }
              cardTitleWorkshop2={
                "Eligible for: 'Sonographers', 'General Practitioners', 'Radiographers',"
              }
              products={masterclass}
              selectedState={selectedWorkshop}
              setSelectedState={setSelectedWorkshop}
              eligibleProfession={eligibleProfession}
              eligibleMemberType={eligibleMemberType}
              purchasedWorkshop={purchasedWorkshop}
              activated
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelected(null);
                  setSelectedWorkshop(null);
                } else {
                  setSelected(null);
                }
              }}
              handleRadio={() => {
                setSelected(null);
              }}
            />
          </>
        )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur {sstRate}% SST
          </strong>
        )}
        <button
          type="submit"
          className="btn btn-lg btn-primary d-block mx-auto mt-4"
          disabled={selected === null && selectedWorkshop === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
