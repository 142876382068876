import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { firebaseDb } from '../../../../../config/firebaseConfig'
import { DeleteButton, DownloadButton } from './components/buttons'
import './styles'

const token = document.getElementsByName('csrf-token')[0].content
const defaultFetchHeader = {
  'X-CSRF-Token': token,
  'Content-Type': 'application/x-www-form-urlencoded',
}
const auditoriumChatRef = `${
  process.env.NODE_ENV === 'production' ? 'production' : 'local'
}/Auditorium_Chat`

const auditoriumQuestionRef = `${
  process.env.NODE_ENV === 'production' ? 'production' : 'local'
}/Auditorium_Question`

const PostEvent = () => {
  const [auditoriumChat, setAuditoriumChat] = useState([])
  const [auditoriumQuestion, setAuditoriumQuestion] = useState([])

  const apiConfig =
    process.env.NODE_ENV === 'production'
      ? 'https://cloud-365.herokuapp.com/'
      : 'http://localhost:3000/'

  const headers = [
    { label: 'User Name', key: 'userName' },
    { label: 'User ID', key: 'userId' },
    { label: 'Message', key: 'message' },
    { label: 'Timestamp', key: 'timestamp' },
  ]

  const auditoriumChatReport = {
    data: auditoriumChat,
    headers: headers,
    filename: `Auditorium_Chat.csv`,
  }

  const auditoriumQuestionReport = {
    data: auditoriumQuestion,
    headers: headers,
    filename: `Auditorium_Question.csv`,
  }

  useEffect(() => {
    firebaseDb.ref(auditoriumChatRef).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || {}
        setAuditoriumChat(
          Object.keys(data).map((key) => ({
            userName: Object.values(data[key])[2],
            userId: Object.values(data[key])[3],
            message: Object.values(data[key])[0],
            timestamp: new Date(Object.values(data[key])[1]).toLocaleString(),
          }))
        )
      },
      (errorObject) => {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [auditoriumChatRef])

  useEffect(() => {
    firebaseDb.ref(auditoriumQuestionRef).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || {}
        setAuditoriumQuestion(
          Object.keys(data).map((key) => ({
            userName: Object.values(data[key])[2],
            userId: Object.values(data[key])[3],
            message: Object.values(data[key])[0],
            timestamp: new Date(Object.values(data[key])[1]).toLocaleString(),
          }))
        )
      },
      (errorObject) => {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [auditoriumQuestionRef])

  const downloadUsers = () => {
    window.open(`${apiConfig}admin/users.csv`, '_blank')
  }

  const onClickDeleteUsers = () => {
    const confirm = window.confirm('Are you sure you want to delete all users?')
    if (confirm) {
      axios
        .request({
          url: '/admin/users/delete-all-users',
          method: 'DELETE',
          headers: defaultFetchHeader,
        })
        .then((resp) => console.log(resp.data))
        .then((json) => callback(json))
        .catch((err) => console.log(err))
      window.alert('All users are deleted!')
    }
  }

  const downloadLoginLogs = () => {
    window.open(`${apiConfig}admin/login_logs.csv`, '_blank')
  }

  const onClickDeleteLoginLogs = () => {
    console.log('hello')
  }

  const onClickDeleteAuditoriumChat = () => {
    const confirm = window.confirm('Are you sure you want to delete all chat?')
    if (confirm) {
      firebaseDb.ref(auditoriumChatRef).remove()
      window.alert('All chat are deleted!')
    }
  }

  const onClickDeleteAuditoriumQuestions = () => {
    const confirm = window.confirm(
      'Are you sure you want to delete all questions?'
    )
    if (confirm) {
      firebaseDb.ref(auditoriumQuestionsRef).remove()
      window.alert('All questions are deleted!')
    }
  }

  return (
    <div>
      <p className="title">Server Active Records</p>
      <div className="outer-container">
        <div className="group-container">
          <span className="sub-header">Existing Users</span>
          <DownloadButton
            onClick={downloadUsers}
            buttonTitle={'Download All Users'}
            helperMessage={
              'Click here to download all users before clearing existing users.'
            }
          />
          <DeleteButton
            onClick={onClickDeleteUsers}
            buttonTitle={'Delete All Users'}
            helperMessage={'Click here to delete all users.'}
          />
        </div>
        <div className="group-container">
          <span className="sub-header">Login Logs</span>
          <DownloadButton
            onClick={downloadLoginLogs}
            buttonTitle={'Download Users Login Logs'}
            helperMessage={
              'Click here to download all login logs before clearing existing login logs.'
            }
          />
          <DeleteButton
            onClick={onClickDeleteLoginLogs}
            buttonTitle={'Delete All Login Logs'}
            helperMessage={'Click here to delete all login logs.'}
          />
        </div>
      </div>
      <hr />
      <p className="title">Firebase Realtime Records</p>
      <div className="outer-container">
        <div className="group-container">
          <span className="sub-header">Auditorium Hall Group Chat</span>
          <div className="download-button">
            <CSVLink {...auditoriumChatReport}>
              Download Auditorium Chat
            </CSVLink>
          </div>
          <small className="text-muted">
            Click here to download all chat before clearing existing chat.
          </small>
          <DeleteButton
            onClick={onClickDeleteAuditoriumChat}
            buttonTitle={'Delete All Auditorium Chat'}
            helperMessage={'Click here to delete all auditorium chat'}
          />
        </div>
        <div className="group-container">
          <span className="sub-header">Auditorium Hall Questions</span>
          <div className="download-button">
            <CSVLink {...auditoriumQuestionReport}>
              Download Auditorium Questions
            </CSVLink>
          </div>
          <small className="text-muted">
            Click here to download all questions before clearing existing
            questions.
          </small>
          <DeleteButton
            onClick={onClickDeleteAuditoriumQuestions}
            buttonTitle={'Delete All Auditorium Questions'}
            helperMessage={'Click here to delete all auditorium questions'}
          />
        </div>
      </div>
      <hr />
    </div>
  )
}

export default PostEvent
