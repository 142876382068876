import React from "react";

export const ProductCard = ({
  cardTitle,
  cardTitlePhysical1,
  cardTitleWorkshop1,
  products,
  activated,
  handleCheckbox,
  selectedState,
  setSelectedState,
  purchasedPhysical,
  purchasedWorkshop,
  eligibleMemberType,
  handleRadio,
}) => {
  const isChecked = selectedState !== null; // 打勾了

  if (products.length === 0) {
    if (purchasedWorkshop || purchasedPhysical) {
      return (
        <div className="card p-4 text-center">
          <h6>{cardTitle}</h6>
          <h6>{cardTitleWorkshop1}</h6>
          <p className="text-success mt-4">
            <em>Purchased</em>
            <i className="fas fa-check ms-2"></i>
          </p>
          <small className="text-muted">{cardTitlePhysical1}</small>
        </div>
      );
    }

    // else if (!eligibleMemberType) {
    //   return (
    //     <div className="card p-4 text-center">
    //       <h6>{cardTitle}</h6>
    //       <h6>{cardTitleWorkshop1}</h6>
    //       <p className="text-danger mt-4">
    //         <em>Not Eligible</em>
    //         <i className="fas fa-times ms-2"></i>
    //       </p>
    //       <small className="text-muted">{cardTitlePhysical1}</small>
    //     </div>
    //   );
    // }
  }

  if (products.every((p) => p.soldOut)) {
    return (
      <div className="card p-4 text-center">
        <h6>{cardTitle}</h6>
        <h6>{cardTitleWorkshop1}</h6>
        <p className="text-danger mt-4">
          <em>Sold Out</em>
        </p>
        <small className="text-muted">{cardTitlePhysical1}</small>
      </div>
    );
  }

  return (
    <div className={isChecked ? "activate card p-4" : "deactivate card p-4"}>
      <div className="cardCheckbox">
        <input
          disabled={!activated}
          className={`form-check-input ${isChecked ? "d-block" : "d-none"}`}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckbox}
        />
      </div>
      <div style={{ opacity: isChecked ? 1 : 0.9 }}>
        <h6>{cardTitle}</h6>
        <h6>{cardTitleWorkshop1}</h6>

        {products.map(
          ({ id, title, price, soldOut, buyerType, productName }) => {
            return (
              <div className="form-check" key={id}>
                <input
                  disabled={!activated || soldOut}
                  className="form-check-input"
                  name={cardTitle}
                  type="radio"
                  checked={selectedState == id}
                  id={id}
                  onChange={() => {
                    if (handleRadio) {
                      handleRadio();
                    }
                    setSelectedState(id);
                  }}
                />
                <label
                  className={`form-check-label ${
                    soldOut && "text-decoration-line-through"
                  }`}
                  htmlFor={id}
                >
                  <small>{title === "virtual" ? buyerType : productName}</small>
                  <strong className="text-danger">{`- ${price}.00`}</strong>

                  {[
                    "wetlab_lunch",
                    "wetlab_afternoon",
                    "wetlab_morning",
                  ].includes(title) && (
                    <p className="m-0 mb-3">
                      <small>(Limited 20 pax only)</small>
                    </p>
                  )}
                  <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                </label>
              </div>
            );
          }
        )}
        <small className="text-muted">{cardTitlePhysical1}</small>
      </div>
    </div>
  );
};
