import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import HallSessions from "./HallSessions";

export const DAY_ONE_HALLS: Array<ProgrammeSession["hall"]> = [
  "Lecture Hall A",
  "Lecture Hall B",
];
export const DAY_TWO_HALLS: Array<ProgrammeSession["hall"]> = [
  "Lecture Hall A",
  "Lecture Hall B",
];
export const DAY_THREE_HALLS: Array<ProgrammeSession["hall"]> = [
  "Lecture Hall A",
  "Lecture Hall B",
];

// export const WORKSHOP_HALLS: Array<ProgrammeSession["hall"]> = ["Workshops"];
// export const SIG_HALLS: Array<ProgrammeSession["hall"]> = ["SIG Meetings"];

const EVENT_DATES = [
  { dayNum: 1, date: "26 July | Friday" },
  { dayNum: 2, date: "27 July | Saturday" },
  { dayNum: 3, date: "28 July | Sunday" },
];

export const FACULTY_TYPE = [
  "Chairperson",
  "Citation",
  "Co-Chair",
  "Emcee",
  "Judge",
  "Guest Of Honor",
  "Speaker",
  "Adjudicator",
  "Moderator",
  "Master of Ceremony",
  "Moderators",
  "Chief Judge",
  "Judges",
  "Facilitators",
  "Panellists",
  "Speakers",
  "Quizmaster",
  "Quizmasters",
  "Coordinator",
] as const;

export enum CATEGORY {
  "lecture_hall",
}

export interface Faculty {
  id: number;
  fullName: string;
  avatar: string;
  shortProfile: string;
}

export interface FacultyWithType extends Faculty {
  facultyType: (typeof FACULTY_TYPE)[number];
}

export interface ProgrammeSession {
  id: number;
  title: string;
  desc?: string;
  startAt: string;
  endAt: string;
  date: string; // 11 JUN | SATURDAY
  time: string; // 10:00 to 11:00
  day: 1 | 2 | 3;
  hallOverwrite?: string;
  hall: "Lecture Hall A" | "Lecture Hall B";
  category: "lecture_hall";
  faculties: Array<FacultyWithType>;
  subsessions: Array<ProgrammeSubsession>;
  secondsTillStart: number;
  durationInSeconds: number;
  ended?: boolean;
}

export interface ProgrammeSubsession {
  id: number;
  title?: string;
  description?: string;
  speakers: Array<Faculty>;
  time: string; // 10:00 to 11:00
}

const Programme = ({ defaultDay }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<CATEGORY>(CATEGORY.lecture_hall);
  const [day, setDay] = useState(defaultDay || 1);
  const [lectureHalls, setLectureHalls] = useState(
    defaultDay === 1
      ? DAY_ONE_HALLS
      : defaultDay === 2
      ? DAY_TWO_HALLS
      : DAY_THREE_HALLS
  );
  const [data, setData] = useState([]);

  const [activeLecture, setActiveLecture] =
    useState<ProgrammeSession["hall"]>("Lecture Hall A");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/programme_sessions/day_${day}`)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [day, category]);

  const dayClass = "btn btn-outline-primary my-1 fw-bold me-2 py-2 fs-5";
  const catClass = "btn btn-outline-danger my-1 fw-bold me-2 py-2 fs-5";

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case "Lecture Hall A":
        return "#fcb1b1";
      case "Lecture Hall B":
        return "#59d4e8";
      default:
        return "";
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="container pb-5" id="programmeAgenda">
      <div className="filterBtns">
        {/* 1st row */}
        <div className="my-4 text-center">
          {EVENT_DATES.map(({ dayNum, date }) => (
            <button
              key={dayNum}
              className={`${dayNum === day && "active"} ${dayClass}`}
              onClick={() => {
                setDay(dayNum);
                if (dayNum === 1) setLectureHalls(DAY_ONE_HALLS);
                if (dayNum === 2) setLectureHalls(DAY_TWO_HALLS);
                if (dayNum === 3) setLectureHalls(DAY_THREE_HALLS);
              }}
            >
              {date}
            </button>
          ))}
        </div>

        {/* 2nd row */}
        <div className="my-3 text-center d-none">
          {/* <small className="d-block mb-1">VENUE</small> */}
          <button
            className={`${
              category === CATEGORY.lecture_hall && "active"
            } ${catClass}`}
            onClick={() => setCategory(CATEGORY.lecture_hall)}
          >
            Lecture Halls
          </button>
        </div>
      </div>

      {/* 3rd row */}
      {/* day1 - 3tabs, day2 - 3tabs, day3 - 3tabs */}
      {category === CATEGORY.lecture_hall && (
        <div className="mt-5">
          {/* mobile */}
          <div className="d-flex d-lg-none">
            {lectureHalls.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>
          {/* desktop */}
          <div className="row nogutters">
            {lectureHalls.map((hall) => (
              <div
                // className={day === 3 ? "col-lg-6" : "col-lg-4 px-2"}
                className="col-lg-6"
                key={hall}
              >
                {/* <div className={"col-lg-4 px-2"} key={hall}> */}
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Programme;
